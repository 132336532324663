<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-deals-lake-matheson-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Deals</h1>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Roys Peak Track, Wanaka</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
        <v-container grid-list-xl fill-height>
          <v-layout row wrap fill-height my-0>
            <v-flex xs12 py-0 my-0>
              <v-card tile class="pa-0 elevation-0 deals-content" color="transparent">
                <v-layout row wrap my-0>
                  <v-flex x12 sm6 lg6 pt-0 pb-4 v-for="(deal, n) in deals" :key="n">
                    <v-card tile class="pa-0 elevation-0 dash-rounded" :href="deal.link">
                      <v-img
                        :src="deal.thumbnail"
                        gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.35)"
                      >
                        <v-container fill-height>
                          <v-layout dark column fill-height justify-space-between>
                            <v-flex
                              text-xs-center
                              class="home-hero-cta deal-title white--text display-1 font-weight-medium"
                              ><img
                                :src="deal.art"
                                :alt="deal.title"
                                class="deal-art"
                                :width="deal.width"
                                :height="deal.height"
                            /></v-flex>
                          </v-layout>
                        </v-container>
                      </v-img>
                      <v-card-text class="pa-4 d-block">
                        <span class="title d-block mb-2">{{ deal.title }}</span>
                        <span class="subheading d-block grey--text">{{ deal.description }}</span>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    deals: [
      {
        title: "New Zealand Coach Tours",
        description: "A collection of some of New Zealand's favourite Guided Coach Tours",
        link: "/deals/new-zealand-coach-tours/",
        thumbnail:
          "https://cdn.anzcro.com.au/wp-content/uploads/2024/06/Coach-Tours-Thumbnail-1280x980-1.jpg",
        art: "https://cdn.anzcro.com.au/wp-content/uploads/2024/11/new-zealand-coach-tours-stacked.png",
        width: 300,
        height: 210,
      },
      {
        title: "2 Nights Auckland. 2 Nights Rotorua. 2 Easy",
        description: "Discover the best of New Zealand with a quick and effortless getaway.",
        link: "/deals/2-easy/",
        thumbnail:
          "https://cdn.anzcro.com.au/wp-content/uploads/2024/09/deal-collection-auckland-rotorua-2easy_1280x980.jpg",
        art: "https://cdn.anzcro.com.au/wp-content/uploads/2024/09/auckland-rotorua-2easy-stacked.png",
        width: 340,
        height: 250,
      },
      {
        title: "Great Rail Journeys of New Zealand",
        description:
          "Discover a collection of our most popular Independent Rail Holidays and Guided Rail Tours.",
        link: "/deals/rail-journeys/",
        thumbnail:
          "https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deals-collection-great-rail-journeys-nz.jpg",
        art: "https://cdn.anzcro.com.au/wp-content/uploads/2023/07/great-rail-journeys-of-new-zealand-stacked.png",
        width: 240,
        height: 150,
      },
      {
        title: "New Zealand Highways Collection",
        description: "Discover South Island's most popular themed road-trips.",
        link: "/deals/new-zealand-highways/",
        thumbnail:
          "https://cdn.anzcro.com.au/wp-content/uploads/2024/05/deal-collection-nz-highways-chc.jpg",
        art: "https://cdn.anzcro.com.au/wp-content/uploads/2024/05/nz-highways-collection-stacked.png",
        width: 240,
        height: 150,
      },
      {
        title: "Iconic New Zealand Itineraries",
        description: "Unforgettable journeys through NZ's breathtaking landscapes.",
        link: "/deals/iconic-new-zealand/",
        thumbnail:
          "https://cdn.anzcro.com.au/wp-content/uploads/2024/04/Iconic-NZ-Thumbnail-1280x980-1.jpg",
        art: "https://cdn.anzcro.com.au/wp-content/uploads/2024/04/iconic-nz-itineraries-stacked.png",
        width: 240,
        height: 150,
      },
      {
        title: "South Pacific Island Paradise",
        description: "Find paradise in the South Pacific with these special offers.",
        link: "/deals/south-pacific/",
        thumbnail:
          "https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deals-collection-south-pacific-aud-4.jpg",
        art: "https://cdn.anzcro.com.au/wp-content/uploads/2021/11/south-pacific-island-paradise-stacked.png",
        width: 240,
        height: 150,
      },
    ],
  }),
  mounted: function () {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.gradient-bar {
  width: 100%;
  height: 9px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#65b32e),
    color-stop(#d2d2d2),
    to(#30302f)
  );
  background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
  margin-top: -60px;
}

.deal-title span {
  display: block;
  max-width: 75%;
}
</style>
